.loginContent {
  overflow: scroll;
  // -ms-overflow-style: none;  /* Internet Explorer 10+ */
  // scrollbar-width: none;  /* Firefox */
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.forWidth {
  .MuiDialog-paperWidthSm {
    max-width: none;
  }
  // .MuiDialog-container {
  //   height: 100% !important;
  // position: fixed !important;
  // top: 0px !important;
  // }
}
.chat-header {
  background-color: #f5f5f5;
  color: #f26222;
  height: 50px;
  padding-top: 5px;
  border: 1px solid #e5e5e5;
  padding-left: 15px;
  padding-right: 15px;
  //@at-root
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 4px;
  margin-right: 4px;
}
//Header
.header {
  background-color: #f5f5f5;
  color: #f26222;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e5e5e5;
  padding-left: 15px;
  padding-right: 15px;
}
.card-header {
  background-color: #f5f5f5;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e5e5e5;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 500;
  font-size: 1.3em;
}
.accordionHeader {
  background-color: #f5f5f5;
  color: #f26222;
  border: 1px solid #e5e5e5;
}
.headerIconButton {
  border: 1px solid #f29b22 !important;
  padding: 8px !important;
}
/* edit note dropdown select custom style start */
.dropDownStyle div {
  width: 100% !important;
  height: 120px !important;
  // height: 70px !important;
}


.dropDownStyle div a{
  line-height: 28px !important;
  width: 100% !important;
}

.dropDownStyle .tag-list{
    display: inline-flex !important;
    width: 96% !important;
}
.dropDownStyle .tag-item{
  width: 100% !important;
}
.dropDownStyle .tag {
  background-color: transparent !important;
  border: none !important;
  padding: 0px !important;
}
.dropDownStyle .tag-remove{
  display: none !important;
}
// .dropDownStyle div a ul{
//   .tag-list{
//     display: inline-flex !important;
//     width: 96% !important;
//   }
// }

.tag-item:last-child{
  width: 93% !important;
}
.dropDownStyle div a ul li input{
border-bottom: 0px !important;
}
.dropDownStyle div a ul li input:focus {
  outline: none !important;
}
.dropDownStyle div a:hover{
text-decoration: none;
}
.dropDownStyle div a:focus{
outline-color: rgb(242, 98, 34) !important;
}

/* edit note dropdown select custom style end */


// Folder
.folderTitle {
  font-weight: 600;
  color: #424242;
}
.firstAddNoteFolder {
  text-align: right;
  margin-top: 3px !important;
}
.secondAddNoteFolder {
  text-align: right;
  margin-top: 3px;
}
.mobileFolderButton {
  margin-bottom: -10px !important;
}
.folderHeader {
  padding: 7px !important;
}
.folderName {
  font-size: 1rem !important;
  color: rgba(0, 0, 0, 0.54);

  // .MuiIconButton-root{
  //     // font-size: 1rem;
  //     font-size: 1rem !important;
  // }
}
//deleteFolder
.headertrashButton {
  margin-right: 5px !important;
  margin-left: 5px !important;
}
//addfolder
.headerFolderPlushButton {
  margin-right: 5px !important;
}
//Links
.linkBold {
  font-weight: 700;
}
//Icons
.clockIcon {
  color: #f29b22;
  margin-right: 10px;
}
.mapIcon {
  color: #f29b22;
  margin-right: 10px;
}
.textFieldChevronIcon {
  color: #f29b22;
  font-size: 2em;
}
.textSearchChevronIcon {
  color: #f29b22;
  font-size: 2em;
}
.inviteIcon {
  color: #f26222;
  margin-right: 10px;
}
.exitIcon {
  color: #f26222;
  margin-right: 10px;
}
.fileUploadIcon {
  font-size: 15px;
  color: #f29b22;
}
.folderPlusIcon {
  font-size: 15px;
  color: #f29b22;
}
.editIcon {
  font-size: 15px;
  color: #f29b22;
}
// datatable
.checkIcon {
  font-size: 15px;
  color: #60c800;
}
.timesIcon{
  font-size: 15px;
  color: #ee0a0a;
}
.actionButton{
  padding: 3px !important;
  background-color: inherit !important;
}
.mainRow .TableCell{
  padding: 0px !important;
}
.cellDesign{
  // padding: 3px !important;
  word-break: break-all;
}
.imageDesign{
  padding: 0px  0px 0px 16px !important;
  div{
    display: inline-flex;
  }
}
.headBold{
  font-weight: 600 !important;
color: #424242;
}
.datatableIcon {
  font-size: 15px;
  color: #f29b22;
}
.datatableIconGrey {
  font-size: 15px;
  color: #4b4a48;
}
.backIcon {
  font-size: 15px;
  color: #f29b22;
}
.infoIcon {
  font-size: 15px;
  color: #b0b0b0;
}
/*dashboard dwhile you were out*/
.iconAllign li{
align-items: flex-start !important;
// align-items: none !important;
}
//Dashboard Notification
.dateStyleOne {
  background-color: #f7f7f9;
  padding: 2px;
  border-radius: 2px;
}

.tableth {
  padding: 0.5rem !important;
}
.tabletd {
  padding: 0.5rem !important;
}
.trfont {
  font-size: 11px;
}
.trStyleOne {
  background-color: #eceeef;
  color: #6d7073;
  font-size: 11px;
}
//Dashboard Upcoming
.upcomingContainer {
  ul {
    padding-left: 20px;
    li {
      margin-bottom: 10px;
    }
    .innerList {
      list-style: none;
    }
  }
}
.upcomingInnerHeading {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px;
}
.upcomingInnerHeadingLight {
  font-weight: 500;
  font-size: 16px;
}
.upcomingInnerTextOrange {
  font-weight: 500;
}
//Classboards
.editButtons {
  margin-top: -30px;
}
.classBoardContent {
  font-weight: 500;
  font-style: italic;
}
.noteFileImage {
  cursor: pointer;
  height: 170px;
  width: 120px;
  background: url("/images/preview.png");
  border: 1px solid #ddd;
  margin-top: 10px;
}
.classBoardVideo {
  max-width: 300px;
  height: auto;
  margin-top: 10px;
}
.contentContainer {
  padding: 15px;
}
//Classes
.class {
  .teacherContainer {
    display: flex;
    align-items: center;
  }
  .teacherIcon {
    margin-right: 10px;
  }
  .classContentContainer {
  }
  .classInnerHeading {
    margin-bottom: 15px;
  }
}
//class Home
.accordiansearch {
  border: 1px solid #f26222 !important;
}
.searchAccordianColor {
  background-color: #e86129 !important;
  .MuiIconButton-root {
    color: white;
  }
}
.searchAccordianTextColor {
  color: #ffffff;
}
.searchResultDivider {
  margin-bottom: 50px !important;
  border: 1px solid #e86129;
  border-radius: 6px 6px 0px 0px !important;
}
.modalContent {
  .classStudent {
    padding: 4px;
  }
}
.classmobile {
  padding-bottom: 7px;
  .MuiTypography-h6 {
    font-size: 1.07rem;
  }
}
//Folder
.folder {
}
.folderInput {
  margin-bottom: 10px;
}
.folderContainerTwo {
  display: flex;
  flex-direction: column;
  // align-items: center;
}
.folderSubContainer {
  padding: 5px;
  vertical-align: center;
  border: 1px solid #e2e2e2 !important;
  border-radius: 9px;
  margin-left: 17px;
  width: 342px;
  //@at-rootpadding: 0px;
  height: 49px;
  margin-top: 20px;
  overflow-wrap: break-word;
  display: inline;
  //@at-root
  word-wrap: break-word;
  // width:fit-content; width:-webkit-fit-content; width:-moz-fit-content;
}

.folderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  .iconName {
    display: flex;
    flex-direction: row;
    //@at-root
    padding: 5px;
    border: 1px solid #e2e2e2 !important;
    border-radius: 9px;
    //@at-rootpadding: 0px;
    margin-top: 20px;
    overflow-wrap: break-word;
    display: inline;
    //@at-root
    word-wrap: break-word;
  }

  // .folderSubContainer{
  //     padding:5px;
  //     vertical-align: center;
  //     border: 1px solid #e2e2e2 !important;
  //     border-radius: 9px;
  //     margin-left: 17px;
  //     width: 342px;
  //     //@at-rootpadding: 0px;
  //     height: 49px;
  //     margin-top: 20px;
  //     overflow-wrap: break-word;
  //     display: inline;
  //     //@at-root
  //     word-wrap: break-word
  //     // width:fit-content; width:-webkit-fit-content; width:-moz-fit-content;
  // }
  .folderName {
    padding-left: 3px;
    // font-weight: bold;
    font-size: 16px;
  }
}

//Notes
.noteFiles {
  margin-top: 5px !important;
  padding: 21px !important;
}
.responsiveBackTitle {
  margin-bottom: -33px;
}
@media screen and (max-width: 768px) {
  // .tempNoteStyle{
  //     margin-left: 30px !important;
  // }
}

.noteContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  .note-heading {
    font-weight: bold;
    font-size: 16px;
  }
  .note-content {
  }
  .note-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    .note-creationDate {
      color: 1px solid rgba(0, 0, 0, 0.122);
      font-size: 12px;
    }
    .note-createdBy {
      font-size: 14px;
    }
  }
}
//Notes
/* iframe design */
.iFrameDesign{
  height: 100% !important;
}
/* iframe dragggable */
.draggable{
  .MuiTypography-h6{
    text-align: right;
  }
cursor: move;
border-bottom: none !important;
padding-bottom:  0px !important;
padding: 0px 0px !important;
}
.actionIframe {
  padding: 0px !important;
}
.MyCustomButton:hover {
  background-color: inherit !important;
}
.MyCustomButton {
  padding: 4px !important;
}
.DialogIframe {
  padding-top: 0px !important;
}
// iframe design

.trashNoteIcon {
  .svg-inline--fa.fa-w-12 {
    width: 1em !important;
  }
  .MuiIconButton-root {
    padding: 5px !important;
  }
}
.editNoteIcon {
  .svg-inline--fa.fa-w-12 {
    width: 1em !important;
  }
  .MuiIconButton-root {
    padding: 5px !important;
  }
}
.fileUpload {
  .svg-inline--fa.fa-w-12 {
    width: 1em !important;
  }
}
.forResponsiveTop {
  margin-top: 1px;
}
.assignmentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  .assignment-header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .assignment-heading {
      font-weight: bold;
      font-size: 16px;
    }
    .assignment-icon-container {
      min-width: 70px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  .assignment-content {
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.122);
    border-bottom: 1px solid rgba(0, 0, 0, 0.122);
    padding: 10px 0px;
  }
  .assignment-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    .assignment-creationDate {
      font-size: 14px;
    }
    .assignment-createdBy {
      color: 1px solid rgba(0, 0, 0, 0.122);
      font-size: 12px;
    }
  }
}
.calendarMargin {
  margin-top: -11px !important;
}
.alladdinput {
  // .MuiInputBase-root{
  //     margin-top: -9px;
  // }
}
.alladdinputMain {
  // .MuiInputBase-root{
  //     margin-top: -18px;
  // }
}
//Accordions
.Mui-expanded {
  margin-top: 0px !important;
}
.MuiAccordionSummary-root.Mui-expanded {
  min-height: auto !important;
}
.MuiAccordionSummary-content {
  margin: 0px !important;
}
//Buttons
.buttonsContainer {
}
.rightButton {
  justify-content: flex-end;
}
.buttonIcon {
  margin-right: 10px;
}
.blueButton {
  background-color: #4287f5 !important;
  color: white !important;
  width: 100%;
  // width: 50px;
}
.blueButton:hover {
  background-color: #215ec0 !important;
}
.yellowButton {
  background-color: #f29b22 !important;
  color: white !important;
  width: 100%;
  // width: 50px;
}
.yellowButton:hover {
  background-color: #d18419 !important;
}
.greenButton {
  background-color: #5bb531 !important;
  color: white !important;
  width: 100%;
  // width: 50px;
}
.w80 {
  width: 80px;
}
.greenButton:hover {
  background-color: #438d21 !important;
}
.orangeButton {
  background-color: #f26222 !important;
  color: white !important;
  width: 100%;
}
.orangeButton:hover {
  background-color: #be4815 !important;
}
.redButton {
  background-color: #b53131 !important;
  color: white !important;
  width: 100%;
}
.redButton:hover {
  background-color: #971515 !important;
}
.darkBlueButton {
  background-color: #3150b5 !important;
  color: white !important;
  width: 100%;
}
.darkBlueButton:hover {
  background-color: #133092 !important;
}
//General
.w50 {
  width: 50%;
}
.w25 {
  width: 25%;
}
.tb {
  border: 1px solid red;
}
.hidden {
  display: none;
}
.bb {
  border-bottom: 1px solid rgba(0, 0, 0, 0.122) !important;
  margin-bottom: 1px !important;
  // margin-bottom: 14px !important;
}
.br {
  border-right: 1px solid rgba(0, 0, 0, 0.122);
}
.bl {
  border-left: 1px solid rgba(0, 0, 0, 0.122);
}

.flexAlignCenter {
  display: flex;
  align-items: center;
}
.flexJustifyCenter {
  display: flex;
  justify-content: center;
}
.flexJustifySpace {
  display: flex;
  justify-content: space-around;
}
.flexJustifySpaceBetween {
  display: flex;
  justify-content: space-between !important;
}
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flexJustifyCenter {
  display: flex;
  justify-content: center;
}
.fl1 {
  flex: 1;
}
.italic {
  font-style: italic;
}
.light {
  color: 1px solid rgba(0, 0, 0, 0.122);
}
.w100 {
  width: 100%;
}
.m0 {
  margin: 0px !important;
}
.align-left {
  text-align: left;
}
//Inputs
.postInput {
  margin-bottom: 10px;
}
.bottomInput {
  .MuiInputBase-fullWidth {
    width: 96%;
    margin-left: 12px;
  }
}
//List
.listItemBlock {
  display: block !important;
}
//Alert
.alertBox {
  margin-bottom: 10px;
}
//Color
.orange {
  color: #f26222;
}
//Overrides
.MuiFormControlLabel-label {
  flex: 1;
}
//Avatar
.userAvatarMedium {
  width: 75px !important;
  height: 75px !important;
}
.userAvatarLarge {
  width: 100px !important;
  height: 100px !important;
}
//profile component
.profileAvatarOuterBorder {
  border: 2px solid;
  border-radius: 50%;
}
.profileAvatarInnerBorder {
  border: 2px solid;
  color: #ffffff;
  border-radius: 50%;
}
//class avatar
.classAvatarOuterBorderProfessor {
  border: 2px solid;
  color: #e86129;
  border-radius: 50%;
  width: 48px;
}
.classAvatarInnerBorder {
  border: 2px solid;
  color: #ffffff;
  border-radius: 50%;
}
// userAvatar component
.userImageContainer{
  margin-bottom: 18px;
}
.userAvatarOuterBorderProfessor {
  border: 2px solid;
  border-radius: 50%;
  color: #e86129;
}
.userAvatarOuterBorderStudent {
  border: 2px solid;
  border-radius: 50%;
  color: #dddddd;
}
.useAvatarInnerBorder {
  border: 2px solid;
  color: white;
  border-radius: 50%;
}


//Table
.noPaddingTable {
  tr {
    td {
      padding: 0px;
      max-width: 15px;
      word-break: break-word;
    }
  }
}
//Calendar
.calendarContainer {
  height: 100vh;
}
.rbc-event {
  background-color: #f26222 !important;
  border: 1px solid #c84b15 !important;
}
.task {
  padding: 15px;
  margin-bottom: 5px;
}
.completedTask {
  text-decoration: line-through;
}
.task-name {
  font-size: 16px;
}
.btnforCalendar {
  .MuiButton-root {
    margin-bottom: -10px;
  }
}
/**/

//Chat
// for mobile start
.participantContainer{
  height: 260px;
  overflow: scroll;
  }
.participantContainer::-webkit-scrollbar {
  width: 10px;
  border-radius: 2px;
}
.participantContainer::-webkit-scrollbar-thumb {
  background: rgb(242, 98, 34) !important;
}
// for mobile end
// for desktop start
  .participantContainerDesktop{
    height: 448px;
    overflow: scroll;
  }
  .participantContainerDesktop::-webkit-scrollbar {
    width: 5px;
    border-radius: 2px;
  }
  .participantContainerDesktop::-webkit-scrollbar-thumb {
    background: rgb(242, 98, 34) !important;
  }
  // for desktop end
.chatMemberName {
  text-decoration: underline;
  // 
  // font-size: 15px;
}
.contentContainerForChat {
  padding-bottom: 13px;
  height: 50%;
  
}
.chatData {
  .MuiPaper-root {
    height: 5px;
  }
}

.chatData {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: unset;
  height: 100%;
  padding-bottom: 10px;
  // padding-bottom: 50px;
}
.arrowSize {
  font-size: 15px;
  // font-size:0.8em;
  // margin-left: 5px;
}
.chatData::-webkit-scrollbar {
  width: 15px;
  height: 100%;
  border-radius: 2px;
}
.chatData::-webkit-scrollbar-thumb {
  background: rgb(242, 98, 34) !important;
}

.chatContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  // padding: 15px;
}

.selectedChat {
  background-color: #7fc0f9 !important;
  .chatName {
    color: white;
  }
}
.chatCount {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.chatMessagesContainer {
  margin: 0.25em;
  // margin: 1em;
  .chatMessageLeftContainer {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .chatMessageLeft {
      width: 80%;
      background-color: #f6ddb9;
      padding: 1em;
      border-radius: 1em;
    }
  }
  .chatMessageRightContainer {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .chatMessageRight {
      width: 80%;
      margin-right: 3px;
      // width: 50%;
      background-color: #cad8f8;
      padding: 1em;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      border-radius: 1em;
    }
  }
  .chatMessageFrom {
    margin-left: 4.2em;
  }
  .emptyAvatar {
    width: 2.9em;
  }
  .chatDateTime {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
}
.newGroupChat {
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}
.newGroupChatName {
  margin-left: 10px;
}
.chatHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  // margin-bottom: 2px;
  .chatHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    .chatAvatar {
      margin-left: 10px;
      margin-right: 10px;
    }
    .chatName {
      color: #f26222;
      font-weight: bold;
      font-size: 0.8em;
      padding: 1px;
      // font-size: 1em;
    }
  }
  .chatIcon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    .chatButton {
      margin-right: 36px;
    }
  }
}

//margintop chat
.chatMargin {
  margin-top: -15px !important;
}
.chatDetailHeight {
  height: 100%;
  // height: 409px;
}

//
.btnforChat {
  width: 250px;
  margin-bottom: 7px !important;
}
.chatButton {
  text-align: right;
}
.dateShow {
  margin-top: 6px !important;
}
.dateFirstRow {
  padding-right: 0px;
  padding-left: 0px;
}
.dateSecondRow {
  padding-right: 0px;
  padding-left: 0px;
}
.chatDetailBack {
  margin-top: -21px;
  margin-bottom: -41px;
  background-color: white;
  padding-bottom: 10px;
}

//Classboard Notifications
.nowhitespace {
  white-space: pre-wrap !important;
}
//Profile
.profileAvatar {
  width: 10em !important;
  height: 10em !important;
}
// Search bar Height
.searchLink {
  a {
    color: white;
  }
}
.hsearch {
  .MuiInputBase-root {
    height: 38px;
  }
}

@media screen and (max-width: 480px) {
  .searchBottomNotes {
    // margin-bottom: -18px !important;
  }
  .searchBottom {
    margin-bottom: -18px !important;
  }
  .chat-header {
    background-color: #f5f5f5;
    color: #f26222;
    // height: 100px;
    padding-top: 18px;
    border: 1px solid #e5e5e5;
    padding-left: 15px;
    padding-right: 15px;
  }
}

//   @media screen and  (min-width: 1025px ) and (max-width: 1440px){
//     .LargeScreenHeight{
//         .MuiPaper-root{
//             height:700px !important;
//         }
//     }
//   }
.chatHeight {
  // height: 100%;
     height: 75vh;
  // overflow: scroll;
}
@media screen and (min-width: 768px) and (max-width: 1539px) {
  // for desktop start
  .participantContainerDesktop::-webkit-scrollbar {
    width: 5px;
    height: 50% !important;
    border-radius: 2px;
  }
  .participantContainerDesktop::-webkit-scrollbar-thumb {
    background: rgb(242, 98, 34) !important;
  }
  .participantContainerDesktop{
    height: 72vh;
  overflow: scroll;
  }
  // for desktop end
  .chatHeaderContainer {
    .chatHeading {
      .chatName {
        font-size: 1em;
      }
    }
  }
  .contentContainerForChat {
    padding-bottom: 13px;
    height: 100%;
  }
  .chatData {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: scroll;
    height: 66vh;
    // height: 45vh;
    padding-bottom: 10px;
    // padding-bottom: 50px;
  }

  .chatHeaderContainer {
    .chatHeading {
      margin-top: 30px;
    }
  }
}
@media screen and (min-width: 1540px) {
  // for desktop start
  .participantContainerDesktop::-webkit-scrollbar {
    width: 5px;
    height: 50% !important;
    border-radius: 2px;
  }
  .participantContainerDesktop::-webkit-scrollbar-thumb {
    background: rgb(242, 98, 34) !important;
  }
  .participantContainerDesktop{
    height: 72vh;
  overflow: scroll;
  }
  // for desktop end
  .chatHeaderContainer {
    .chatHeading {
      .chatName {
        font-size: 1em;
      }
    }
  }
  .contentContainerForChat {
    padding-bottom: 13px;
    height: 100%;
  }
  .chatData {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: scroll;
    height: 67vh;
    // height: 55vh;
    padding-bottom: 10px;
    // padding-bottom: 50px;
  }

  .chatHeaderContainer {
    .chatHeading {
      margin-top: 30px;
    }
  }
}
//for flowpaper
.header-centre {
  display: table;
  margin: 0 auto;
  padding: 0px 200px;
  text-align: center;
}
.header-right {
  position: absolute;
  right: 16px;
  top: 4px;
}
.annotation-panel {
  margin-left: 0px;
  margin-right: 0px;
  border-top: 1px solid black;
}
.autocompactstyle {
  // background-image:url(images/annotations/pencil.png);
}
.annotationstyle {
  // background-image:url(images/annotations/icon-eye-gray.png);
}
.toolbarstyle {
  position: fixed;
  bottom: 5px;
  width: 220px;
  left: 50%;
  margin-left: -110px;
}

.toolbar1style {
  width: 220px;
  display: block;
  margin: auto;
  margin-top: 3px;
}
//paper padding top
.makeStyles-container-36 {
  padding-top: 11px !important;
}

.makeStyles-container-41 {
  height: 100%;
}

//Chat edits per screen resolution
.MuiGrid-container {
  height: 100%;
}

@media screen and (min-width: 600px) {
  .MuiContainer-root {
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
}
@media screen and (min-width: 426px) and (max-width: 768px) {
  .chatData{
    height: 56vh !important;
    overflow: scroll;
  }
  .chatHeight {
    height: 80vh;
    overflow: hidden;
  }
  .contentContainerForChat {
    padding-bottom: 13px;
    height: 50%;
  }
  .chat-header {
    height: 75px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .smallUi {
    width: 113vh;
  }
  .chatDetailHeight {
    height: 70vh;
  }
  .mobilechatbackButton {
    margin-top: -3px !important;
    margin-left: -19px !important;
  }
  .mobilechatOnlineButton {
    margin-top: -35px !important;
    margin-left: 48px !important;
  }
  .chatheaderCard {
    margin-top: -63px !important;
    margin-left: 207px !important;
    position: relative;
  }
  .chatName {
    font-size: 16px !important;
  }
  .bb {
    border-bottom: 0px solid rgba(0, 0, 0, 0.122) !important;
    margin-bottom: 0px !important;
  }
  .mobilebackButtonTwo {
    margin-top: -21px !important;
    margin-left: -13px !important;
  }
  .chatMessageLeft {
  }
  .chatMessageRight {
  }
  .chatIcon {
    margin-right: 140px;
  }
}
@media screen and (min-width: 376px) and (max-width: 425px) {
  .chatData{
    height: 49vh !important;
    overflow: scroll;
  }
   /*editnote dropdown select width */
   .tag-item:last-child{
    width: 87% !important;
  }
  .bottomInput {
    .MuiInputBase-fullWidth {
      width: 92% !important;
    }
  }
  .chatHeight {
    height: 74vh;
    overflow: hidden;
  }
  .chat-header {
    height: 75px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .yellowButton-calendar {
    width: 302px;
    margin-left: 29px !important;
  }

  .btnforChat {
    width: 210px;
    margin-bottom: 7px;
  }
  .rbc-btn-group {
    margin-left: 7px;
  }
  .rbc-toolbar-label {
    margin-top: 2px;
  }
  .dateShow {
    font-size: 13.5px;
    margin-top: 7px !important;
  }
  .smallUi {
    width: 37vh;
  }
  .mobilechatbackButton {
  }
  .mobilechatOnlineButton {
    margin-top: -36px !important;
    margin-left: 38px !important;
  }

  .mobilebackButtonTwo {
    margin-left: -13px !important;
    margin-bottom: 41px !important;
  }
  .chatheaderCard {
    margin-top: -66px !important;
    margin-left: 48px !important;
    position: absolute;
  }
  .chatCrossButton {
    margin-left: -8px;
    margin-right: 19px !important;
  }
  .bb {
    border-bottom: 0px solid rgba(0, 0, 0, 0.122) !important;
    margin-bottom: 0px !important;
  }
  .chatMessageLeft {
    font-size: 11px;
  }
  .chatMessageRight {
    font-size: 11px;
  }
  .chatIcon {
    margin-right: 38px;
  }
}

@media screen and (min-width: 321px) and (max-width: 375px) {
  .chatData{
    height: 50vh !important;
    overflow: scroll;
  }
    /*editnote dropdown select width */
    .tag-item:last-child{
      width: 87% !important;
    }
 
  .chatHeight {
    height: 73vh;
    overflow: hidden;
  }
  .btnforChat {
    width: 160px !important;
    margin-bottom: 21px !important;
  }

  .yellowButton-calendar {
    width: 300px;
  }
  .chat-header {
    margin-left: 0px;
    margin-right: 0px;
    height: 60px;
  }
  .rbc-toolbar-label {
    margin-top: 2px;
  }
  .dateShow {
    font-size: 10.5px;
    margin-top: 10px !important;
  }
  .smallUi {
    width: 34vh;
  }
  .mobilechatbackButton {
  }
  .mobilechatOnlineButton {
    margin-top: -30px !important;
    margin-left: 38px !important;
  }
  .mobilebackButtonTwo {
    margin-top: -22px !important;
    margin-left: -13px !important;
  }
  .chatheaderCard {
    margin-top: -51px !important;
    margin-left: 25px !important;
    position: absolute;
  }
  .chatCrossButton {
    margin-left: -8px;
    margin-right: 19px !important;
  }
  .bb {
    border-bottom: 0px solid rgba(0, 0, 0, 0.122) !important;
    margin-bottom: 0px !important;
  }
  .chatMessageLeft {
    font-size: 11px;
  }
  .chatMessageRight {
    font-size: 11px;
  }
  .chatIcon {
    margin-right: 21px;
  }
  .bottomInput {
    .MuiInputBase-fullWidth {
      width: 90% !important;
      margin-left: 12px;
    }
  }
}
@media screen and (max-width: 320px) {
  /*editnote dropdown select width */
  .tag-item:last-child{
    width: 87% !important;
  }
  .chatData{
    height: 53vh !important;
    overflow: scroll;
  }
 
  .bottomInput {
    .MuiInputBase-fullWidth {
      width: 92% !important;
      // width: 70% !important;
      margin-left: 12px;
    }
  }
  .chatHeight {
    height: 100%;
    overflow: scroll;
    // overflow: hidden;
  }

  .btnforChat {
    width: 219px;
  }
  .chat-header {
    padding-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    height: 85px;
  }
  .rbc-btn-group {
    font-size: 12px;
  }
  .rbc-toolbar-label {
    margin-top: 2px;
  }
  .yellowButton-calendar {
    width: 250px;
    margin-left: -2px !important;
  }
  .dateShow {
    margin-top: 9px !important;
    font-size: 11px;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .smallUi {
    width: 25vh;
  }
  .mobilechatbackButton {
  }
  .mobilechatOnlineButton {
    margin-top: -31px !important;
    margin-left: 38px !important;
  }
  .mobilebackButtonTwo {
    margin-top: -7px !important;
    margin-left: -13px !important;
  }
  .chatheaderCard {
    margin-top: -65px !important;
    margin-left: 16px !important;
    position: absolute;
  }
  .bb {
    border-bottom: 0px solid rgba(0, 0, 0, 0.122) !important;
    margin-bottom: 0px !important;
  }
  .chatMessageLeft {
    font-size: 11px;
  }
  .chatMessageRight {
    font-size: 11px;
  }
  .alignonlineMobile {
    margin-left: 67px !important;
  }
  .chatHeading {
    margin-left: 16px;
  }
  .chatIcon {
    .chatButton {
      margin-right: 40px !important;
    }
  }
  .userplus {
    margin-left: -74px !important;
  }
  .chatCrossButton {
    margin-right: 83px !important;
  }
  .chatHeaderContainer {
    .chatIcon {
      margin-right: -32px;
    }
  }
}

// @media screen and (min-width: 1025px) and (max-width: 1439px) {
//   .btnforCalendar .MuiButton-root {
//     // margin-left: 5px;
//     // margin-left: -26px;
//     // width: 301px;
//   }
//   .contentContainerForChat {
//     padding-bottom: 13px;
//     height: 100%;
//   }
//   .chatData {
//     display: flex;
//     flex: 1;
//     flex-direction: column;
//     overflow: scroll;
//     height: 40vh;
//     padding-bottom: 10px;
//     // padding-bottom: 50px;
//   }
// }
// @media screen and (max-width: 1440px) {
//   .btnforCalendar .MuiButton-root {
//     // margin-left: 5px;
//     // margin-left: -26px;
//     // width: 301px;
//   }
//   .contentContainerForChat {
//     padding-bottom: 13px;
//     height: 100vh;
//   }
//   .chatData {
//     display: flex;
//     flex: 1;
//     flex-direction: column;
//     overflow: scroll;
//     height: 60vh;
//     padding-bottom: 10px;
//     // padding-bottom: 50px;
//   }
// }

// @media screen and (min-width: 1441px) and (max-width: 2000px) {
//   .btnforCalendar .MuiButton-root {
//     // margin-left: 5px;
//     // margin-left: -26px;
//     // width: 301px;
//   }
//   .contentContainerForChat {
//     padding-bottom: 13px;
//     height: 72vh;
//   }
//   .chatData {
//     display: flex;
//     flex: 1;
//     flex-direction: column;
//     overflow: scroll;
//     height: 55vh;
//     padding-bottom: 10px;
//     // padding-bottom: 50px;
//   }
// }

// @media screen and (min-width: 2000px) and (max-width: 2199px) {
//   .contentContainerForChat {
//     padding-bottom: 13px;
//     height: 80vh;
//   }
//   .chatData {
//     display: flex;
//     flex: 1;
//     flex-direction: column;
//     overflow: scroll;
//     height: 65vh;
//     padding-bottom: 10px;
//     // padding-bottom: 50px;
//   }
// }

// @media screen and (min-width: 2200px) {
//   .btnforCalendar .MuiButton-root {
//     // margin-left: 5px;
//     // margin-left: -26px;
//     // width: 301px;
//   }
//   .contentContainerForChat {
//     padding-bottom: 13px;
//     height: 80vh;
//   }
//   .chatData {
//     display: flex;
//     flex: 1;
//     flex-direction: column;
//     overflow: scroll;
//     height: 68vh;
//     padding-bottom: 10px;
//     // padding-bottom: 50px;
//   }
// }


  @media screen and (min-width: 2300px) {
    // for desktop start
    .participantContainerDesktop::-webkit-scrollbar {
      width: 5px;
      height: 50% !important;
      border-radius: 2px;
    }
    .participantContainerDesktop::-webkit-scrollbar-thumb {
      background: rgb(242, 98, 34) !important;
    }
    .participantContainerDesktop{
      height: 83vh;
    overflow: scroll;
    }
    // for desktop end
  .contentContainerForChat {
    padding-bottom: 13px;
    height: 100%;
  }
  .chatData {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: scroll;
    min-height: 83vh;
    // min-height: 70vh;
    padding-bottom: 10px;
    // padding-bottom: 50px;
  }
  .chatHeight {
    height: 100%;
    overflow: scroll;
  }
  .bottomInput .MuiInputBase-fullWidth {
    width: 99%;
  }
}

/* Administrator Screen */
// for cross button of autocomplete start
.auto-cross{
  .MuiAutocomplete-clearIndicator {
    display: none !important;
  }
}
// for cross button of autocomplete start

.onlySearchIcon{
  color: #e86129;
}
/* table */
.forDesign{
  margin-left: 0px !important;
}
#title {
  text-align: center;
}

#employee {
  border-collapse: collapse;
  border: 3px solid #ddd;
}

#employee td,
#employee th {
  border: 1px solid #ddd;
  padding: 12px;
}

#employee tr:hover {
  background-color: #ddd;
}

#employee th {
  padding: 10px;
  text-align: center;

  background-color: #e86129;
  // background-color: #4caf50;
  color: white;
}

.opration {
  text-align: center;
}

.button {
  border: none;
  outline: none;
  font-size: 11px;
  font-family: "Quicksand", sans-serif;
  color: #f44336;
  padding: 3px 10px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f44336;
  background-color: transparent;
}

.button:active {
  border: 1px solid blue;
}

//whatsapp chat
.app__body{
  background-color: #dadbd3;
}

// add term container & dropdown start
.autoContainer::-webkit-scrollbar {
  width: 10px;
  border-radius: 2px;
}
.autoContainer::-webkit-scrollbar-thumb {
  background: rgb(242, 98, 34) !important;
}

.autoContainer{
  margin-top: -9px !important;
  height:123px;
  // height:100px;
  overflow: scroll;
  border-right:  1px solid #424242;
  border-bottom: 1px solid #424242;
  border-left:   1px solid #424242;
}
.option{
    font-size: 15px;
    width: 100%;
    padding:7px;
}
.autoContainer div:hover{
  background: #0092FF !important;
  padding: 7px;
  color: white;
}
// add term container & dropdown end


// join term modal start
.jointTermScroll{
  overflow: scroll;
}
.jointTermScroll::-webkit-scrollbar {
  width: 10px;
  border-radius: 2px;
}
.jointTermScroll::-webkit-scrollbar-thumb {
  background: rgb(242, 98, 34) !important;
}

.jointTermDividerRemove .MuiListItem-divider{
  border-bottom: none !important;
}

.orangeButtonBorder{
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 5px;
}
.termData{
  width:55%;
}
// join term modal end

.iframe-wrapper {
  position: relative;
  height: 82vh;
  // height: 100%;
  // height: 650px;
  width:   100%;
}

.iframe {
  display: block;
  position: absolute;
  top:    0;
  bottom: 0;
  left:   0;
  right:  0;
  width:  100px;
  min-width:  100%;
  height: 100px;
  min-height: 100%;
}
