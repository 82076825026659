.mainFrame {
  display: flex;
  // align-items: center;
  // justify-content: center;
  // min-height: 100vh;
  height:90vh;
  // background: #27ae60;
  // font-family: "proxima-nova", "Source Sans Pro", sans-serif;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 1em;
  letter-spacing: 0.1px;
  color: #424242;
  // color: #32465a;
  text-rendering: optimizeLegibility;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  -webkit-font-smoothing: antialiased;
  margin-bottom: 20px;
}

#frame {
  width: 100%;
  // width: 95%;
  // min-width: 360px;
  // max-width: 1000px;
  // height: 92vh;
  // min-height: 300px;
  // max-height: 486px;
  // max-height: 2400px;
  // max-height: 720px;
  height: calc(100% - 7%);
  background: #ffffff;
  // background: #E6EAEA;
  border: 1px solid #E6EAEA;
}
@media screen and (max-width: 360px) {
  #frame {
    width: 100%;
    height: 100vh;
  }
}
#frame #sidepanel {
  float: left;
  min-width: 280px;
  // max-width: 333px;
  max-width: 340px;
  width: 40%;
  height: 100%;
  // background: #2c3e50;
  color: #435f7a;
  // color: #f5f5f5;
  overflow: hidden;
  position: relative;
}
@media screen and (max-width: 320px) {
  #frame #sidepanel #profile .wrap .img {
    margin: -2px 5px 5px 4px !important;
    // margin: 0px 5px 5px 4px !important;
  }
  #frame #sidepanel {
    // margin-left: -7px;
    // margin-left: 44px;
  }
  #frame .content {
    width: 100% !important;
    // width: calc(100% - 58px) !important;
  }
  #frame .content .messages{
    width: 187px;
    margin-bottom: 10px;
  }
 
  #frame .content .contact-profile p {
    // width: 10px;
    overflow-wrap: break-word;
    font-size: 12px !important;
    color: #e86129;
  }
  .socialMediaButtons{
    // .MuiIconButton-sizeSmall {
      font-size: 0.8rem !important;
  // }
}
#frame .content .contact-profile img {
  // width: 35px !important;
  border-radius: 50%;
  float: left;
  // margin: 13px 6px 0 6px !important;
}
#frame .content .contact-profile .member-modal {
  font-size: 11px !important;
}
}
@media screen and (min-width: 321px) and (max-width: 375px) {
  #frame #sidepanel #profile .wrap .img {
    margin: -2px 5px 5px 4px !important;
    // margin: 0px 5px 5px 4px !important;
  }
  #frame #sidepanel {
    // margin-left: -9px;
  }
  #frame .content {
    width: 100% !important;
    // width: calc(100% - 58px) !important;
  }
  #frame .content .messages{
    width: 243px;
  }
  #frame .content .contact-profile p {
    font-size: 18px;
    color: #e86129;
  }
  #frame .content .contact-profile .member-modal {
    font-size: 11px !important;
  }
}
@media screen and (min-width: 376px) and (max-width: 425px) {
  #frame #sidepanel #profile .wrap .img {
    margin: -2px 5px 5px 4px !important;
    // margin: 0px 5px 5px 4px !important;
  }
  #frame #sidepanel {
    // margin-left: -9px;
  }
  #frame .content {
    width: 100% !important;
    // width: calc(100% - 58px) !important;
  }
  #frame .content .messages{
    width: 294px;
  }
  .social-media{
    margin-right: 9px !important;
  }

}
@media screen and (min-width: 426px) and (max-width: 768px) {
  #frame #sidepanel {
    // margin-left: -7px;
  }
  #frame .content {
    // commentedwidth1
    width: calc(100% - 280px) !important;
  }
  #frame .content .messages{
    width: 408px;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  #frame #sidepanel {
    // margin-left: -5px;
  }
  #frame .content {
    width: calc(100% - 280px) !important;
    // width: calc(100% - 298px) !important;
  }
  #frame .content .messages{
    width: 467px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #frame #sidepanel {
    // margin-left: -7px;
  }
  #frame .content {
    width: calc(100% - 340px) !important;
  }
  #frame .content .messages{
    // width: 544px;
  }
}
@media screen and (min-width: 1440px) {
  #frame .content {
    width: calc(100% - 340px) !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 1024px) {
  #frame {
    max-height: 390px !important;
}
.mainFrame {
  min-height: 0vh !important;
}
}

// chat badge mobile design start
.chatBadgeDesign{
  .MuiBadge-badge {
    padding: 0 4px !important;
    margin-top: 4px !important;
  }
}
// chat badge mobile design end

  @media screen and (max-width: 735px) {
  // image icon width adjustment start
    #frame #sidepanel #contacts ul li.contact .wrap .img {
width: 42px!important;
    }
  // image icon width adjustment end
  // responsive tweeking start
#frame .content .contact-profile .responsiveBackButton {
  width: 50px;
  border-radius: 50%;
  padding: 3px;
  height: auto;
  float: left;
  cursor: pointer;
  -moz-transition: 0.3s border ease;
  -o-transition: 0.3s border ease;
  -webkit-transition: 0.3s border ease;
  transition: 0.3s border ease;
  // margin: 5px;
  // margin: 15px 3px 0 -13px;
  margin: 0px 0px 0 -16px;
  .MuiListItemAvatar-root {
    min-width: 0px; 
}
}




#frame .content .contact-profile .img {
  margin: 5px 0 0 4px !important;
}
// responsive tweeking end

  #frame #sidepanel {
    width: 100%;
    // width: 58px;
    min-width: 58px;
  }
// chat badge mobile design start
  .chatBadgeDesign{
    .MuiBadge-badge {
      // height: 8px;
      height: 13px;
      padding: 0 3px !important;
      font-size: 0.5rem;
      // min-width: 8px;
      min-width: 13px;
      margin-top: 2px !important;
      border-radius: 20px;
    }
  }
// for active avatar bubble size start
.active .MuiBadge-dot{
    height: 13px!important;
    padding: 0;
    min-width: 13px !important;
    border-radius: 20px !important;
}
// for active avatar bubble size end

//for top image bubble size start
.contact-profile  .MuiBadge-dot{
  height: 13px!important;
  padding: 0;
  min-width: 13px !important;
  border-radius: 20px !important;
}
//for top image bubble size end

// chat badge mobile design end

}
#frame #sidepanel #profile {
    background: #ffffff;
    color: #ee9a20;
    border-bottom: 1px solid #e5e5e5 !important;
    font-size: 20px;
  width: 100%;
  margin: 0px !important;
  // background: #ee9a20;
  // width: 80%;
  // color: #ffffff;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile {
    width: 100%;
    margin: 0 auto;
    padding: 5px 0 0 0;
    background: #ffffff;
    // background: #32465a;
  }
}
#frame #sidepanel #profile.expanded .wrap {
  height: 210px;
  line-height: initial;
}
#frame #sidepanel #profile.expanded .wrap p {
  margin-top: 20px;
}
#frame #sidepanel #profile.expanded .wrap i.expand-button {
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}
#frame #sidepanel #profile .wrap {
  height: 59px;
  line-height: 60px;
  overflow: hidden;
  -moz-transition: 0.3s height ease;
  -o-transition: 0.3s height ease;
  -webkit-transition: 0.3s height ease;
  transition: 0.3s height ease;
  border-right: 1px solid #e5e5e5;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap {
    height: 55px;
  }
}
#frame #sidepanel #profile .wrap img {
  border-radius: 50%;
  height: 50px;
  float: left;
  cursor: pointer;
  -moz-transition: 0.3s border ease;
  -o-transition: 0.3s border ease;
  -webkit-transition: 0.3s border ease;
  transition: 0.3s border ease;
  margin: 5px;
}
#frame #sidepanel #profile .wrap .img {
  width: 50px;
  border-radius: 50%;
  padding: 3px;
  // border: 2px solid #e74c3c;
  height: auto;
  // height: 40px;
  // width:40px;
  float: left;
  cursor: pointer;
  -moz-transition: 0.3s border ease;
  -o-transition: 0.3s border ease;
  -webkit-transition: 0.3s border ease;
  transition: 0.3s border ease;
  margin: 3px 5px 5px 5px;
  // margin: 5px 5px 5px 5px;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap img {
    width: 40px;
    margin-left: 4px;
  }
}
#frame #sidepanel #profile .wrap img.online {
  border: 2px solid #2ecc71;
}
#frame #sidepanel #profile .wrap img.away {
  border: 2px solid #f1c40f;
}
#frame #sidepanel #profile .wrap img.busy {
  border: 2px solid #e74c3c;
}
#frame #sidepanel #profile .wrap img.offline {
  border: 2px solid #95a5a6;
}
#frame #sidepanel #profile .wrap p {
  float: left;
  // margin-left: 15px;
  color: #e86129;
  font-size: 19px;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap p {
    // display: none;
    // display: none;
  }
}
#frame #sidepanel #profile .wrap i.expand-button {
  float: right;
  margin-top: 23px;
  font-size: 0.8em;
  cursor: pointer;
  color: #435f7a;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap i.expand-button {
    display: none;
  }
}
#frame #sidepanel #profile .wrap #status-options {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  width: 150px;
  margin: 70px 0 0 0;
  border-radius: 6px;
  z-index: 99;
  line-height: initial;
  background: #435f7a;
  -moz-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options {
    width: 58px;
    margin-top: 57px;
  }
}
#frame #sidepanel #profile .wrap #status-options.active {
  opacity: 1;
  visibility: visible;
  margin: 75px 0 0 0;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options.active {
    margin-top: 62px;
  }
}
#frame #sidepanel #profile .wrap #status-options:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid #435f7a;
  margin: -8px 0 0 24px;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options:before {
    margin-left: 23px;
  }
}
#frame #sidepanel #profile .wrap #status-options ul {
  overflow: hidden;
  border-radius: 6px;
}
#frame #sidepanel #profile .wrap #status-options ul li {
  padding: 15px 0 30px 18px;
  display: block;
  cursor: pointer;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options ul li {
    padding: 15px 0 35px 22px;
  }
}
#frame #sidepanel #profile .wrap #status-options ul li:hover {
  background: #496886;
}
#frame #sidepanel #profile .wrap #status-options ul li span.status-circle {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 5px 0 0 0;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options ul li span.status-circle {
    width: 14px;
    height: 14px;
  }
}
#frame #sidepanel #profile .wrap #status-options ul li span.status-circle:before {
  content: '';
  position: absolute;
  width: 14px;
  height: 14px;
  margin: -3px 0 0 -3px;
  background: transparent;
  border-radius: 50%;
  z-index: 0;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options ul li span.status-circle:before {
    height: 18px;
    width: 18px;
  }
}
#frame #sidepanel #profile .wrap #status-options ul li p {
  padding-left: 12px;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options ul li p {
    display: none;
  }
}
#frame #sidepanel #profile .wrap #status-options ul li#status-online span.status-circle {
  background: #2ecc71;
}
#frame #sidepanel #profile .wrap #status-options ul li#status-online.active span.status-circle:before {
  border: 1px solid #2ecc71;
}
#frame #sidepanel #profile .wrap #status-options ul li#status-away span.status-circle {
  background: #f1c40f;
}
#frame #sidepanel #profile .wrap #status-options ul li#status-away.active span.status-circle:before {
  border: 1px solid #f1c40f;
}
#frame #sidepanel #profile .wrap #status-options ul li#status-busy span.status-circle {
  background: #e74c3c;
}
#frame #sidepanel #profile .wrap #status-options ul li#status-busy.active span.status-circle:before {
  border: 1px solid #e74c3c;
}
#frame #sidepanel #profile .wrap #status-options ul li#status-offline span.status-circle {
  background: #95a5a6;
}
#frame #sidepanel #profile .wrap #status-options ul li#status-offline.active span.status-circle:before {
  border: 1px solid #95a5a6;
}
#frame #sidepanel #profile .wrap #expanded {
  padding: 100px 0 0 0;
  display: block;
  line-height: initial !important;
}
#frame #sidepanel #profile .wrap #expanded label {
  float: left;
  clear: both;
  margin: 0 8px 5px 0;
  padding: 5px 0;
}
#frame #sidepanel #profile .wrap #expanded input {
  border: none;
  margin-bottom: 6px;
  background: #32465a;
  border-radius: 3px;
  // color: #f5f5f5;
  color: #32465a;
  padding: 7px;
  width: calc(100% - 43px);
}
#frame #sidepanel #profile .wrap #expanded input:focus {
  outline: none;
  background: #435f7a;
}
#frame #sidepanel #search {
  // border: 1px solid #32465a;
  // border-top: 1px solid #dad7d7;
  border-bottom: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  // border-bottom: 1px solid #32465a;
  font-weight: 300;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #search {
    display: block;
    // display: none;
  }
}
#frame #sidepanel #search label {
  position: absolute;
  margin: 10px 0 0 20px;
}
#frame #sidepanel #search input {
  font-family: "proxima-nova",  "Source Sans Pro", sans-serif;
  padding: 10px 0 10px 46px;
  width: calc(100% - 0px);
  border: none;
  background: #ffffff;
  // background: #32465a;
  // color: grey !important;
  color: #424242 !important;
  // color: #32465a !important;
  // color: #f5f5f5;
  
}
#frame #sidepanel #search .fa{
  color: #424242 !important;
}

#frame #sidepanel #search input:focus {
  outline: none;
  background: #ffffff;
  // background: #435f7a;
}
#frame #sidepanel #search input::-webkit-input-placeholder {
  // color: black;
  color: #435f7a;
  // color: #ee9a20;
}
#frame #sidepanel #search input::-moz-placeholder {
  // color: #f5f5f5;
  color: #435f7a;

}
#frame #sidepanel #search input:-ms-input-placeholder {
  // color: #f5f5f5;
  color: #435f7a;
}
#frame #sidepanel #search input:-moz-placeholder {
  // color: #f5f5f5;
  color: #435f7a;
}
#frame #sidepanel #contacts {
  height: calc(100% - 138px);
  // height: calc(100% - 149px);
  overflow-y: scroll;
  overflow-x: hidden;
  border-right: 1px solid #e5e5e5;
}
#frame #sidepanel #contacts::-webkit-scrollbar-thumb {
  background: #ee9a20 !important;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts {
    height: calc(100% - 149px);
    overflow-y: scroll;
    overflow-x: hidden;
  }
  #frame #sidepanel #contacts::-webkit-scrollbar-thumb {
    // display: none;
    background: #ee9a20 !important;
  }
}
#frame #sidepanel #contacts.expanded {
  height: calc(100% - 334px);
}
#frame #sidepanel #contacts::-webkit-scrollbar {
  width: 8px;
  background: #ffffff;
  // background: #2c3e50;
}
#frame #sidepanel #contacts::-webkit-scrollbar-thumb {
  background-color: #243140;
}
#frame #sidepanel #contacts ul li.contact {
  position: relative;
  padding: 10px 0 15px 0;
  font-size: 0.9em;
  cursor: pointer;
  border-bottom: 1px solid #dad7d7;

}
@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact {
    padding: 6px 0 0px 8px;
    margin-left: 0px;
    // padding: 6px 0 46px 8px;
  }
}
#frame #sidepanel #contacts ul li.contact:hover {
  background: #f5f5f5;
  // background: #32465a;
}
#frame #sidepanel #contacts ul li.contact.active {
  // background: #7fc0f9;
  background: #f5f5f5;
  // background: #32465a;
  border-right: 5px solid #f5f5f5;
  // border-right: 5px solid #435f7a;
}
#frame #sidepanel #contacts ul li.contact.active span.contact-status {
  border: 2px solid #32465a !important;
}
#frame #sidepanel #contacts ul li.contact .wrap {
  width: 88%;
  margin: 0 auto;
  position: relative;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact .wrap {
    width: 100%;
  }
}
#frame #sidepanel #contacts ul li.contact .wrap span {
  // position: absolute;
  // left: 0;
  // margin: -2px 0 0 -2px;
  // width: 10px;
  // height: 10px;
  // border-radius: 50%;
  // border: 2px solid #2c3e50;
  // background: #95a5a6;
}
#frame #sidepanel #contacts ul li.contact .wrap span.online {
  background: #2ecc71;
}
#frame #sidepanel #contacts ul li.contact .wrap span.away {
  background: #f1c40f;
}
#frame #sidepanel #contacts ul li.contact .wrap span.busy {
  background: #e74c3c;
}
#frame #sidepanel #contacts ul li.contact .wrap .img {
  // width: 40px;
  width: 40px;
  border-radius: 50%;
  float: left;
  margin-right: 10px;
}
#frame #sidepanel #contacts ul li.contact .wrap img {
  width: 40px;
  border-radius: 50%;
  float: left;
  // margin-right: 10px;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact .wrap img {
    margin-right: 0px;
  }
}
#frame #sidepanel #contacts ul li.contact .wrap .meta {
  padding: 5px 0 0 0;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact .wrap .meta {
    display: block;
    // display: none;
  }
}
#frame #sidepanel #contacts ul li.contact .wrap .meta .name {
  // font-weight: 600;
  color: #424242;
  // font-size: 1.1em;
  // font-size: 1em;
  margin-left: 51px;
  //
  font-size: 0.875rem;
  strong, b{
    font-weight: 600;
  }
 
}
#frame #sidepanel #contacts ul li.contact .wrap .meta .preview {
  color: #424242;
  margin: 5px 0 0 0;
  padding: 0 0 1px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-transition: 1s all ease;
  -o-transition: 1s all ease;
  -webkit-transition: 1s all ease;
  transition: 1s all ease;
  margin-left: 51px;
  font-size: 14px;
}
#frame #sidepanel #contacts ul li.contact .wrap .meta .preview span {
  position: initial;
  border-radius: initial;
  background: none;
  border: none;
  padding: 0 2px 0 0;
  margin: 0 0 0 1px;
  opacity: .5;
}
#frame #sidepanel #bottom-bar {
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index:1;
}
#frame #sidepanel #bottom-bar .button1 {
  float: left;
  border: none;
  width: 50%;
  padding: 13px 0;
  // padding: 12px 0;
  // padding: 10px 0;
  background: #ee9a20;
  // background: #e86129;
  // background: #32465a;
  color: #f5f5f5;
  // color: #f5f5f5;
  cursor: pointer;
  font-size: 0.85em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  // font-family: "proxima-nova",  "Source Sans Pro", sans-serif;
  .addGroup{
// padding-left: 13px;
  }
}
#frame #sidepanel #bottom-bar .button2 {
  float: left;
  border: none;
  width: 50%;
  padding: 4.5px 0;
  // padding: 4px 0;
  // padding: 10px 0;
  background: #ee9a20;
  // background: #e86129;
  // background: #32465a;
  color: #f5f5f5;
  // color: #f5f5f5;
  cursor: pointer;
  font-size: 0.85em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  // font-family: "proxima-nova",  "Source Sans Pro", sans-serif;
  .MuiTypography-body1 {
  font-size: 1.1em;
  }
  .MuiSwitch-track {
    background-color: grey;
  }
  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: white;
  }
  
}
// #frame #sidepanel #bottom-bar button {
//   float: left;
//   border: none;
//   width: 50%;
//   padding: 14px 0;
//   // padding: 10px 0;
//   background: #ee9a20;
//   // background: #e86129;
//   // background: #32465a;
//   color: #f5f5f5;
//   // color: #f5f5f5;
//   cursor: pointer;
//   font-size: 0.85em;
//   font-family: "proxima-nova",  "Source Sans Pro", sans-serif;
// }
@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar .button1 {
    float: none;
    width: 49%;
    padding: 13px 0;
    // width: 100%;
    // padding: 17px 0;
  }
  #frame #sidepanel #bottom-bar .button2 {
    float: right;
    width: 50%;
    padding: 3px 0;
    // padding: 1px 0;
    // float: none;
    // width: 100%;
    // padding: 3px 0;
    // padding: 15px 0;
    .MuiFormControlLabel-root {
    margin-left: 0px;
    display: block;
  }
  .PrivateSwitchBase-root-68 {
    padding: none;
}
.MuiSwitch-root {
padding: 14px;
}
.MuiSwitch-thumb {
  width: 18px !important;
  height: 18px !important;
}
.MuiTypography-body1 {
  font-size: 0.8rem !important;
}
.MuiTypography-root {
  padding-left: 10px !important;
}
  
}
  // #frame #sidepanel #bottom-bar button {
  //   float: none;
  //   width: 100%;
  //   padding: 17px 0;
  //   // padding: 15px 0;
  // }
}
#frame #sidepanel #bottom-bar button:focus {
  outline: none;
}
#frame #sidepanel #bottom-bar button:nth-child(1) {
  border-right: 1px solid white;
  // border-right: 1px solid #e86129;
  // border-right: 1px solid #2c3e50;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar button:nth-child(1) {
    border-bottom: 1px solid #ffffff;
    border-right: none;
    // border-bottom: 1px solid #2c3e50;
  }
  .lastChat{
    margin-bottom: 100px;
  }
}
#frame #sidepanel #bottom-bar button:hover {
  // background: #435f7a;
}
#frame #sidepanel #bottom-bar button i {
  margin-right: 3px;
  font-size: 1.7em;
  // font-size: 1em;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar button i {
    font-size: 1.3em;
  }
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar .button1 span {
    font-size: 1.0em;
    // display: block;
    .MuiTypography-body1 {
      padding-left: 4px;
    }
     
  }
  #frame #sidepanel #bottom-bar .button2 span {
    font-size: 0.9em;
    // display: block;
    // display: block;
    // margin-left: 3px;
    // display: none;
    .MuiTypography-body1 {
      padding-left: 4px;
    }
   
     
  }
}
#frame .content {
  float: right;
  width: 60%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background: #f5f5f5;
}
@media screen and (max-width: 735px) {
  #frame .content {
    width: calc(100% - 58px);
    // min-width: 300px !important;
  }
  #frame #sidepanel #bottom-bar .button2 .MuiTypography-root {
    /* padding-left: 10px !important; */
    padding-left: 0px !important;;
}
}
@media screen and (min-width: 900px) {
  #frame .content {
    // width: calc(100% - 280px) !important;
    // width: calc(100% - 330px) !important;
    width: calc(100% - 340px) !important;
  }
  
  
}




#frame .content .contact-profile {
  background: #ffffff;
  color: #ee9a20;
  font-size: 20px;
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #E6EAEA;
  border-left: 1px solid #E6EAEA;
  // background: #f5f5f5;
  // background: #ee9a20;
  // color: #ffffff;
}
#frame .content .contact-profile img {
  width: 40px;
  border-radius: 50%;
  float: left;
  // margin: 9px 12px 0 9px;
}
#frame .content .contact-profile .img {
  // width: 50px;
  // border-radius: 50%;
  // float: left;
  // margin: 9px 12px 0 0px;
  // margin: 9px 12px 0 9px;
  //self
  width: 50px;
  border-radius: 50%;
  padding: 3px;
  // border: 2px solid #e74c3c;
  height: auto;
  float: left;
  cursor: pointer;
  -moz-transition: 0.3s border ease;
  -o-transition: 0.3s border ease;
  -webkit-transition: 0.3s border ease;
  transition: 0.3s border ease;
  margin: 5px;
  .MuiListItemAvatar-root {
    min-width: 0px; 
}
}

#frame .content .contact-profile p {
  float: left;
  font-size: 19px;
  color: #e86129;
}
#frame .content .contact-profile .name{
  float: left;
}
#frame .content .contact-profile .social-media {
  float: right;
  margin-right: 5px;
}
#frame .content .contact-profile .social-media i {
  margin-left: 14px;
  cursor: pointer;
}
#frame .content .contact-profile .social-media i:nth-last-child(1) {
  margin-right: 20px;
}
#frame .content .contact-profile .social-media i:hover {
  color: #435f7a;
}







#frame .content .contact-profile .member-modal {
  float: left;
  margin-top: 18px;
  font-size: 15px;
  margin-left: 59px;
  position: absolute;
}

#frame .content .messages {
  height: auto;
  min-height: calc(100% - 93px);
  max-height: calc(100% - 93px);
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  background: #f5f5f5;
}
@media screen and (max-width: 735px) {
  #frame .content .messages {
    max-height: calc(100% - 105px);
  }
}
#frame .content .messages::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}
#frame .content .messages::-webkit-scrollbar-thumb {
  // background-color: rgba(0, 0, 0, 0.3);
  background: #ee9a20 !important;

}

#frame .content .messages ul li {
  display: inline-block;
  clear: both;
  float: left;
  margin: 15px 15px 5px 15px;
  width: calc(100% - 25px);
  font-size: 0.9em;
}
#frame .content .messages ul li:nth-last-child(1) {
  margin-bottom: 20px;
}
#frame .content .messages ul li.sent img {
  // margin: 6px 8px 0 0;
}
#frame .content .messages ul li.sent .img {
  float: left;
  margin: 6px 8px 0 0;
  cursor: auto !important;
}
#frame .content .messages ul li.sent p {
  // commented2
  // background: #ee9a20;
  // background: #435f7a;
  background: #ffffff;
  color: #424242;
  // color: #435f7a;
  // color: #f5f5f5;
  font-size: 15px;
}
#frame .content .messages ul li.replies img {
  float: right;
  // margin: 6px 0 0 8px;
}
#frame .content .messages ul li.replies .img {
  float: right;
  margin: 6px 0 0 8px;
  cursor: auto !important;
}
#frame .content .messages ul li.replies p {
  
  background: #e86129;
  // background: #f5f5f5;
  float: right;
  color: #ffffff;
  font-size: 15px;

}
#frame .content .messages ul li img {
  // width: 22px;
  border-radius: 50%;
  float: left;
}
#frame .content .messages ul li p {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 205px;
  line-height: 130%;
}
@media screen and (min-width: 735px) {
  #frame .content .messages ul li p {
    max-width: 300px;
  }
}
#frame .content .message-input {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 99;
}
#frame .content .message-input .wrap {
  position: relative;
}
#frame .content .message-input .wrap input {
  font-family: "proxima-nova",  "Source Sans Pro", sans-serif;
  float: left;
  border: none;
  width: calc(100% - 50px);
  // width: calc(100% - 90px);
  // padding: 11px 32px 8px 8px;
  padding: 11px 32px 11px 8px;
  font-size: 1.1em;
  // color: #32465a;
  color: #424242;
}
@media screen and (max-width: 735px) {
  #frame .content .message-input .wrap input {
    padding: 15px 32px 21px 8px;
  font-size: 0.83em;

    // padding: 15px 32px 16px 8px;
  }
}
#frame .content .message-input .wrap input:focus {
  outline: none;
}
#frame .content .message-input .wrap .attachment {
  position: absolute;
  right: 60px;
  z-index: 4;
  margin-top: 10px;
  font-size: 1.1em;
  color: #435f7a;
  opacity: .5;
  cursor: pointer;
}
@media screen and (max-width: 735px) {
  #frame .content .message-input .wrap .attachment {
    margin-top: 17px;
    right: 65px;
  }
}
#frame .content .message-input .wrap .attachment:hover {
  opacity: 1;
}
#frame .content .message-input .wrap button {
  float: right;
  border: none;
  width: 50px;
  padding: 12px 0;
  cursor: pointer;
  background: #ee9a20;
  // background: #32465a;
  color: #f5f5f5;
}
@media screen and (max-width: 735px) {
  #frame .content .message-input .wrap button {
    padding: 16px 0;
  }
}
#frame .content .message-input .wrap button:hover {
  // background: #435f7a;
}
#frame .content .message-input .wrap button:focus {
  outline: none;
}
// self styling

.messageBox::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  // color: #ee9a20;
  color: #424242;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  // color: #32465a;
}
.messageBox::-moz-placeholder { /* Firefox 19+ */
  // color: #ee9a20;
  color: #424242;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  // color: #32465a;
}
.messageBox:-ms-input-placeholder { /* IE 10+ */
  // color: #ee9a20;
  color: #424242;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  // color: #32465a;
}
.messageBox:-moz-placeholder { /* Firefox 18- */
  // color: #ee9a20;
  color: #424242;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  // color: #32465a;
}

// group member modal button opening button start
.groupMemberModal{
  margin-left: 61px;
  margin-top: -58px;
}
// group member modal button opening button end

 
// chat content time start
.chat-time{
  float: right;
}
// chat content time end
